import React from "react";

const PrivacyPolicy = () => {
  return (
    <div className="w-full mt-32 ">
      <h3 className="text-3xl text-primary text-center">Privacy Policy</h3>
      <h6 className="text-center text-slate-600">Last updated January 2025</h6>
      <div className="p-9">
        <p>Welcome to Tipkit!</p>
        <p>
          Your privacy is important to Tipkit LLC (the “Company”, “we”, or
          “us”), and we are committed to adhering to federal and state privacy
          laws and industry guidelines regarding the collection, use, and
          disclosure of user information through the website and mobile
          applications.
        </p>
        <p>This Privacy Policy includes:</p>
        <ul className="list-disc ml-5">
          <li>
            <strong>Information Collection:</strong> Tipkit collects business
            and/or personal information (e.g., name, contact details) and usage
            information (e.g., browser type, IP address) through various means,
            including cookies and web beacons.
          </li>
          <li>
            <strong>Information Use:</strong> This information is used to
            provide and improve services, personalize and customize user
            experiences, process transactions, and communicate with users.
          </li>
          <li>
            <strong>Information Sharing:</strong> Tipkit may share
            non-personally identifiable information with third parties for
            analysis and advertising purposes. Personal and business information
            is shared with third-party service providers only for necessary
            purposes and with user consent for direct marketing.
          </li>
          <li>
            <strong>User Choices:</strong> Users can opt-out of targeted
            advertising and modify their communication preferences.
          </li>
        </ul>
        <p>
          This Privacy Policy covers all our online (e.g., web and mobile
          services, Mobile Applications) and offline activities owned, operated,
          provided, or made available by the Company. This includes interactive
          features or downloads that (i) we own or control, (ii) are available
          through the Services, or (iii) interact with the Services and
          incorporate this Privacy Policy.
        </p>
        <p>
          Please review this Privacy Policy carefully. If you do not agree to
          this Privacy Policy, do not use our Services or give us any of your
          information. Additionally, review our Terms of Use, which may apply to
          your use of our websites and mobile applications. If you have
          questions about this Privacy Policy, please contact us at{" "}
          <a href="mailto:hello@tipkit.co" className="text-blue-500 underline">
            hello@tipkit.co
          </a>
          .
        </p>
        <h5 className="text-lg font-semibold my-3">Personal Information</h5>
        <p>
          Although the personal and business information we may collect through
          our Website is limited and voluntary, you are responsible for
          providing the Company with accurate and complete information, and for
          contacting the Company if correction on the information is required.
          We may collect information through various forms and in various places
          through the Services, including account registration forms, user
          profiles, contact us forms, or when you otherwise interact with us.
          us.
        </p>
        <p>
          In addition to any personal or business Information or any other
          information that you choose to submit to us, we, and our third-party
          service providers may use a variety of technologies that automatically
          (or passively) collect certain information whenever you visit or
          interact with the Services (“Usage Information”). This Usage
          Information may include the browser that you are using, the URL that
          referred you to our Services, all of the areas within our Services
          that you visit, the time of day, and your geolocation, among other
          information. We may use Usage Information for a variety of purposes,
          including to enhance or otherwise improve the Services. In addition,
          we collect your IP address or other unique identifier (“Device
          Identifier”) for your computer, mobile or other device used to access
          the Services (a “Device”). A Device Identifier is a number that is
          automatically assigned to your Device used to access the Services, and
          our computers identify your Device by its Device Identifier. Usage
          Information may be non-identifying or may be associated with you.
          Whenever we associate Usage Information or a Device Identifier with
          your personal or business information, we will treat it as personal or
          business information.
        </p>
        <p>
          A few of the methods that may be used to collect Usage Information
          include, without limitation, the following (and subsequent technology
          and methods hereafter developed):
        </p>
        <h6 className="text-md font-semibold my-2">Cookies</h6>
        <p>
          Cookies, log files, and pixel-tags (Web beacons) are technologies used
          by the Company Website for various purposes. A cookie is a data file
          placed on a Device when it is used to access the Services. A Flash
          cookie is a data file placed on a Device via the Adobe Flash plug-in
          that may be built-in to or downloaded by you to your Device. Cookies
          and Flash Cookies may be used for many purposes, including, without
          limitation, remembering you and your preferences and tracking your
          visits to our web pages. Cookies work by assigning a number to the
          user that has no meaning outside of the assigning website.
        </p>
        <p>
          If you do not want information to be collected through the use of
          cookies, your browser allows you to deny or accept the use of cookies.
          Cookies can be disabled or controlled by setting a preference within
          your web browser or on your Device. If you choose to disable cookies
          or Flash cookies on your Device, some features of the Services may not
          function properly or may not be able to customize the delivery of
          information to you.
        </p>
        <p>
          You should be aware that the Company cannot control the use of cookies
          (or the resulting information) by third-parties, and use of third
          party cookies is not covered by our Privacy Policy.
        </p>
        <h6 className="text-md font-semibold my-2">Web Beacons</h6>
        <p>
          Small graphic images or other web programming code called web beacons
          (also known as “1×1 GIFs” or “clear GIFs”) may be included in our web
          and mobile pages and messages. The web beacons are tiny graphics with
          a unique identifier, similar in function to cookies, and are used to
          track the online movements of Web users. In contrast to cookies, which
          are stored in a user’s computer hard drive, web beacons are embedded
          invisibly on Web pages and are about the size of the period at the end
          of this sentence. Web beacons or similar technologies help us better
          manage content on our Services by informing us what content is
          effective, count users of the Services, monitor how users navigate the
          Services, count how many emails that we send were actually opened or
          to count how many particular articles or links were actually viewed.
          We do not tie the information gathered by web beacons to your personal
          information.
        </p>
        <h6 className="text-md font-semibold my-2">Embedded Scripts</h6>
        <p>
          An embedded script is programming code that is designed to collect
          information about your interactions with the Services, such as the
          links you click on. The code is temporarily downloaded onto your
          Device from our web server or a third party service provider, is
          active only while you are connected to the Services, and is
          deactivated or deleted thereafter.
        </p>
        <h6 className="text-md font-semibold my-2">
          Information Third Parties Provide About You
        </h6>
        <p>
          We may, from time to time, supplement the information we collect about
          you through our Services with outside records from third parties in
          order to enhance our ability to serve you, to tailor our content to
          you and to offer you opportunities to purchase products or services
          that we believe may be of interest to you. We may combine the
          information we receive from those sources with information we collect
          through the Services. In those cases, we will apply this Privacy
          Policy to any personal or business information received, unless we
          have disclosed otherwise.
        </p>
        <h6 className="text-md font-semibold my-2">
          Information Collected by Mobile Applications
        </h6>
        <p>
          Our Services are primarily provided through an application on your
          mobile, tablet, computer or similar device (“Mobile Application”). You
          agree that we may collect and use technical data and related
          information, including but not limited to, technical information about
          your device, system and application software, and peripherals, that is
          gathered periodically to facilitate the provision of software updates,
          product support and other services to you (if any) related to such
          Mobile Applications.
        </p>
        <p>
          When you use any of our Mobile Applications, the Mobile Application
          may automatically collect and store some or all of the following
          information from your mobile device (“Mobile Device Information”),
          including without limitation:
        </p>
        <p>
          <li>Your preferred language and country site (if applicable).</li>
          <li>
            Your phone number or other unique device identifier assigned to your
            mobile device – such as the International Mobile Equipment Identity
            or the Mobile Equipment ID number.
          </li>
          <li>The IP address of your mobile device.</li>
          <li>The manufacturer and model of your mobile device.</li>
          <li>Your mobile operating system.</li>
          <li>The type of mobile Internet browsers you are using.</li>
          <li>Your geolocation.</li>
          <li>
            Information about how you interact with the Mobile Application and
            any of our web sites to which the Mobile Application links, such as
            how many times you use a specific part of the mobile application
            over a given time period, the amount of time you spend using the
            Mobile Application, how often you use the Mobile Application,
            actions you take in the Mobile Application and how you engage with
            the Mobile Application.
          </li>
          <li>
            Information to allow us to personalize the services and content
            available through the Mobile Application.
          </li>
        </p>
        <p>
          We may use information automatically collected by the Mobile
          Application (including the Mobile Device Information) to customize
          Services for you and to operate and improve our Services. We may also
          use this information to create aggregated and anonymized information
          to determine which Mobile Application features are most popular and
          useful to users, and for other statistical analyses, or to prevent,
          discover and investigate violations of this Privacy Policy or any
          applicable terms of service or terms of use for the Mobile
          Application, and to investigate fraud, chargeback or other matters.
        </p>
        <p>
          We may associate your unique mobile Device Identifier or Mobile
          Application usage information with any personal or business
          information you provide, but we will treat the combined information as
          personal Information.
        </p>
        <p>
          Personal or business information may also be collected and shared with
          third-parties if there is content from the Mobile Application that you
          specifically and knowingly upload to, share with or transmit to an
          email recipient, website, or to the public (e.g., uploaded photos,
          posted reviews or comments, or information about you that you choose
          to share with others through features which may be provided on our
          Services). This uploaded, shared or transmitted content will also be
          subject to the privacy policy of the email, website, social media or
          other platform to which you upload, share or transmit the content.
        </p>
        <h6 className="text-md font-semibold my-2">
          Use of Collected Information
        </h6>
        <p>
          Our primary goal in collecting your personal or business information
          or Usage Information is to provide you with an enhanced experience
          when using the Services. Based upon the personal or business
          Information you provide us when registering for an account, we will
          send you a welcoming email to verify your username and password. We
          will also communicate with you in response to your inquiries, to
          provide the services you request, and to manage your account. We will
          communicate with you by email, telephone, SMS, or text message, in
          accordance with the account settings you have selected.
        </p>
        <p>
          We use the information collected from our Services so that we are able
          to serve you the correct application version depending on your device
          type, for troubleshooting and in some cases, marketing purposes. The
          payment method and account information you provide in your personal or
          business profile at sign-up is not stored by us, but is stored and
          used by our third party credit card processors in order for them to
          process payment.
        </p>
        <p>
          We use your Internet Protocol (IP) address to help diagnose problems
          with our computer server, and to administer our website(s). Your IP
          address is used to help identify you, but contains no personal or
          business information about you.
        </p>
        <p>
          We will send you strictly service-related announcements on rare
          occasions when it is necessary to do so. For instance, if our Services
          are temporarily suspended for maintenance, we might send you an email
          or SMS. Generally, you may not opt-out of these communications, which
          are not promotional in nature. If you do not wish to receive them, you
          have the option to deactivate your account.
        </p>
        <p>
          In addition, we may use your personal or business Information or Usage
          Information that we collect about you:
        </p>
        <p>
          <li>
            To provide you with information or services or process transactions
            that you have requested or agreed to receive including to send you
            electronic newsletters, or to provide you with special offers or
            promotional materials on behalf of third parties or us.
          </li>
          <li>
            To process your registration with the Services, including verifying
            your information is active and valid.
          </li>
          <li>
            To improve our services, to customize your experience with the
            Services, or to serve you specific content that is most relevant to
            you.
          </li>
          <li>
            To enable you to participate in a variety of the Services’ features
            such as online or mobile entry contests or other promotions.
          </li>
          <li>
            To contact you with regard to your use of the Services and, in our
            discretion, changes to the Services and/or the Services’ policies.
          </li>
          <li>For internal business purposes.</li>
          <li>For inclusion in our data analytics.</li>
          <li>
            For purposes disclosed at the time you provide your information or
            as otherwise set forth in this Privacy Policy.
          </li>
        </p>

        <h6 className="text-md font-semibold my-2">
          Disclosure of Information to Third Parties
        </h6>
        <p>
          We may share non-personally identifiable information, such as
          aggregated user statistics and log data, with third parties for
          industry analysis, demographic profiling, to deliver targeted
          advertising about other products or services, or for other business
          purposes. We do not sell, share, rent, trade, or voluntarily provide
          to others any personally identifiable information collected on our
          Website. We do not share your personal or business information with
          third parties for those third parties’ direct marketing purposes
          unless you consent or specify to such sharing at the time you provide
          your information.
        </p>

        <h6 className="text-md font-semibold my-2">
          When You Agree To Receive Information From Third Parties{" "}
        </h6>
        <p>
          You may be presented with an opportunity to receive information and/or
          marketing offers directly from third parties. If you do agree to have
          your personal or business information shared, your personal or
          business information will be disclosed to such third parties and all
          information you disclose will be subject to the privacy policy and
          practices of such third parties. We are not responsible for the
          privacy policies and practices of such third parties and, therefore,
          you should review the privacy policies and practices of such third
          parties prior to agreeing to receive such information from them. If
          you later decide that you no longer want to receive communication from
          a third party, you will need to contact that third party directly.
        </p>
        <h6 className="text-md font-semibold my-2">
          Third Parties Service Providers
        </h6>
        <p>
          We use third parties to facilitate our Services, provide or perform
          certain aspects of the Services on our behalf, third-parties to host
          the Services, design and/or operate the Services’ features, track the
          Services’ analytics, process payments, engage in anti-fraud and
          security measures, provide support, provide geolocation information,
          enable us to send you special offers, perform technical services, or
          perform other administrative services. We may provide these vendors
          with access to user information, including personal or business
          information; this information sharing is limited to only the
          information needed by the vendor to carry out the services they are
          performing for you or for us. Each of these vendors is obligated not
          to disclose or use personal or business information for any other
          purpose.
        </p>
        <p>
          While we may use third party analytics service providers to evaluate
          and provide us with information about the use of the Services and
          viewing of our content, we do not share personal or business
          information with these analytics service providers, but they may set
          and access their own cookies, web beacons and embedded scripts on your
          Device and they may otherwise collect or have access to information
          about you, including non-personally identifiable information.
        </p>
        <h6 className="text-md font-semibold my-2">Co-Branded Services</h6>
        <p>
          Certain aspects of the Services may be provided to you in association
          with third parties (“Co-Branded Services”) such as sponsors and
          charities, and may require you to disclose personal or business
          information to them. Such Co-Branded Services will identify the third
          party. If you elect to register for products and/or services through
          the Co-Branded Services, you may be providing your information to both
          the third party and us. Further, if you sign-in to a Co-Branded
          Service with a username and password obtained through our Services,
          your personal or business information may be disclosed to the
          identified third parties for that Co-Branded Service and will be
          subject to their posted privacy policies.
        </p>
        <h6 className="text-md font-semibold my-2">Contests and Promotions</h6>
        <p>
          We may offer contests, and other promotions ( “Promotion”) through the
          Services that may require registration. By participating in a
          Promotion, you are agreeing to official rules that govern that
          Promotion, which may contain specific requirements of you, including,
          allowing the sponsor of the Promotion to use your name, voice and/or
          likeness in advertising or marketing associated with the Promotion. If
          you choose to enter a Promotion, personal or business information may
          be disclosed to third parties or the public in connection with the
          administration of such Promotion, including, in connection with winner
          selection, prize fulfillment, and as required by law or permitted by
          the Promotion’s official rules, such as on a winners list.
        </p>
        <h6 className="text-md font-semibold my-2">
          Administrative and Legal Reporting Reasons
        </h6>
        <p>
          We cooperate with government and law enforcement officials and private
          parties to enforce and comply with the law. Thus, we may access, use,
          preserve, transfer and disclose your information ( personal or
          business information), including disclosure to third parties such as
          government or law enforcement officials or private parties as we
          reasonably determine is necessary and appropriate:
        </p>
        <p>
          <li>
            To satisfy any applicable law, regulation, subpoenas, governmental
            requests or legal process.
          </li>
          <li>
            To protect and/or defend the Terms of Use for the Services,
            including investigation of potential violations thereof.
          </li>
          <li>To protect the Company, our Services or any third party.</li>
          <li>To protect the safety of the public for any reason.</li>
          <li>
            To detect, prevent or otherwise address fraud, security or technical
            issues.
          </li>
          <li>
            To prevent or stop activity we may consider to be, or to pose a risk
            of being, an illegal, unethical, or legally actionable activity.
            Further, we may use IP address or other Device Identifiers, to
            identify users, and may do so in cooperation with third parties such
            as copyright owners, internet service providers, wireless service
            providers and/or law enforcement agencies, including disclosing such
            information to third parties, all at our discretion. Such
            disclosures may be carried out without notice to you.
          </li>
        </p>
        <p>
          We may share your information, including your personal or business
          information and Usage Information with our affiliated businesses and
          sister companies for internal reasons. We also reserve the right to
          disclose and transfer all such information to a subsequent owner,
          co-owner or operator of the Services or applicable database or in
          connection with a corporate merger, consolidation, restructuring, the
          sale of substantially all of our membership interests and/or assets or
          other corporate change, including, during the course of any due
          diligence process.
        </p>
        <h6 className="text-md font-semibold my-2">User-Generated Content</h6>
        <p>
          The Services may offer publicly accessible blogs or community forums
          or other ways to permit you to submit ideas, photographs, user
          profiles, writings, music, video, audio recordings, computer graphics,
          pictures, data, questions, comments, suggestions or other content,
          including personal or business information (collectively, “User
          Content”). Others or we may reproduce, publish, distribute or
          otherwise use User Content online or offline in any media or format
          (currently existing or hereafter developed). Others may have access to
          this User Content and may have the ability to share it with third
          parties across the Internet. You should be aware that any User Content
          you provide in these areas may be read, collected, and used by others
          who access them. Thus, please think carefully before deciding what
          information you share, including personal or business information, in
          connection with your User Content. Please note that the Company does
          not control who will have access to the information that you choose to
          make public, and cannot ensure that parties who have access to such
          publicly available information will respect your privacy or keep it
          secure. This Privacy Policy does not apply to any information that you
          disclose publicly, share with others or otherwise upload, whether
          through the Services or otherwise. We are not responsible for the
          accuracy, use or misuse of any content or information that you
          disclose or receive through the Services.
        </p>
        <h6 className="text-md font-semibold my-2">
          Third Party Content and Links to Third Party Services
        </h6>
        <p>
          The Services may contain content that is supplied by a third party,
          and those third parties may collect web site usage information and
          your Device Identifier when web pages from any online or mobile
          Services are served to your browser. In addition, when you are using
          the Services, you may be directed to other sites or applications that
          are operated and controlled by third parties that we do not control.
          We are not responsible for the privacy practices employed by any of
          these third parties. These other web sites may send their own cookies
          to you, independently collect data or solicit personal or business
          information and may or may not have their own published privacy
          policies. We encourage you to note when you leave our Services and to
          read the privacy statements of all third party web sites or
          applications before submitting any personal or business information to
          third parties.
        </p>
        <h6 className="text-md font-semibold my-2">Social Media</h6>
        <p>
          Our online and mobile Services may include social media features, such
          as LinkedIn, Instagram, etc, and widgets such as a “Share This”
          button, or interactive mini-programs that run on our online and mobile
          Services. These features may collect your IP address, which page you
          are visiting on our online or mobile Services, and may set a cookie to
          enable the feature to function properly. Social media features and
          widgets are either hosted by a third party or hosted directly on our
          online Services. Your interactions with these features and widgets are
          governed by the privacy policy of the company providing them.
        </p>
        <h6 className="text-md font-semibold my-2">
          Modifications and Account Management
        </h6>
        <p>
          You are responsible for maintaining the accuracy of the information
          you submit to us, such as your contact information provided as part of
          account registration. If your personal or business information
          changes, or if you no longer desire our Services, you may correct,
          delete inaccuracies, or amend information by making the change on your
          dashboard or by contacting us at{" "}
          <a href="mailto:hello@tipkit.co" className="text-blue-500 underline">
            hello@tipkit.co
          </a>{" "}
          We will make good faith efforts to make requested changes in our
          active databases as soon as reasonably practicable.
        </p>
        <p>
          You may also cancel or modify your communications that you have
          elected to receive from the Services by following the instructions
          contained within an e-mail or by logging into your user account and
          changing your communication preferences.
        </p>
        <p>
          If you wish to cancel your account or request that we no longer use
          your information to provide you services, you may do so through your
          user account page or contact us at{" "}
          <a href="mailto:hello@tipkit.co" className="text-blue-500 underline">
            hello@tipkit.co
          </a>{" "}
          We will retain your personal or business information and Usage
          Information (including geolocation) for as long as your account with
          the Services is active and as needed to provide you services. Even
          after your account is terminated, we will retain your personal or
          business information and Usage Information (including geolocation and
          transaction history) as needed to comply with our legal and regulatory
          obligations, resolve disputes, conclude any activities related to
          cancellation of an account, investigate or prevent fraud and other
          inappropriate activity, to enforce our agreements, and for other
          business reasons. After a period of time, your data may be anonymized
          and aggregated, and then may be held by us as long as necessary for us
          to provide our Services effectively, but our use of the anonymized
          data will be solely for analytic purposes.
        </p>
        <h6 className="text-md font-semibold my-2">Age-Appropriate Access</h6>
        <p>
          The Company cares about the safety of children. Because our Services
          are not directed toward minors, no one under eighteen (18) is allowed
          to register with or use the Services. We do not knowingly collect
          personal or business information from anyone under the age of eighteen
          (18). If we discover that we have collected personal information from
          a person under eighteen (18), we will delete that information
          immediately. If you are a parent or guardian of a minor under the age
          of eighteen (18) and believe he or she has disclosed personal
          Information to us, please contact us at{" "}
          <a href="mailto:hello@tipkit.co" className="text-blue-500 underline">
            hello@tipkit.co
          </a>
        </p>
        <h6 className="text-md font-semibold my-2">
          Protection of Information
        </h6>
        <p>
          The personal or business information and Usage Information we collect
          is securely stored within our databases, and we use standard,
          industry-wide, commercially reasonable security practices such as
          encryption, firewalls and SSL (Secure Socket Layers, stay PCI
          compliant and participate in audits, for protecting your information –
          such as any portions of your credit card number which we retain (we do
          not ourselves retain your entire credit card information) and
          geolocation information. However, as effective as encryption
          technology is, no security system is impenetrable.
        </p>
        <p>
          We make a genuine effort to ensure the security of activities
          conducted on this Website and we take various security measures to
          protect information received online. The Company also maintains an
          internal compliance team to assess security risks and monitor
          compliance with its policies and procedures. We cannot guarantee that
          information you supply won’t be intercepted while being transmitted to
          us over the Internet or wireless communication, and any information
          you transmit to the Company you do at your own risk.
        </p>
        <p>
          This Privacy Policy is inclusive of how we collect, use, and store
          personal data from UK residents, including the specific purposes for
          data collection, how long data is retained, and the individual rights
          to access and control their personal data, as mandated by the UK’s
          data protection laws, primarily the UK GDPR ( General Data Protection
          Regulation).
        </p>
        <h6 className="text-md font-semibold my-2">Privacy Policy Updates</h6>
        <p>
          From time to time, we may update this Privacy Policy to reflect
          changes to our information practices and disclosures. Any changes will
          be effective immediately upon the posting of the updated Privacy
          Policy. We encourage you to periodically review this page for the
          latest information on our privacy practices. Your continued use of our
          Services after the update, constitutes your acceptance of the new
          policy.
        </p>
      </div>
    </div>
  );
};

export default PrivacyPolicy;
